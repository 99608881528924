import {currentUserChangeDispatcher} from './user'
import {BACKEND} from '~/config'
import {handleHttpError} from '~/services/error'
import {http} from '~/utils/http'

const audioElement = document.createElement('audio')

export function synthesize(
    voiceId: string,
    text: string,
    speed: number,
    language = 'auto',
    sampleRate?: number,
    emotion?: string,
) {
    return http.post<Blob>(`${BACKEND.api}/tts/synthesize`, {
        voice: voiceId,
        text,
        language,
        speed,
        sample_rate: sampleRate,
        emotion,
    })
        .catch(handleHttpError)
}

export function synthesizeDemo(
    voiceId: string,
    text: string,
    speed: number,
    language = 'auto',
    emotion?: string,
) {
    return http.post<Blob>(`${BACKEND.api}/demo/tts/synthesize`, {voice: voiceId, text, language, speed, emotion})
        .catch(handleHttpError)
}

type Emotion = {
    name: string
    display_name: string
}

export type Voice = {
    id: string
    name: string
    version: string
    languages: string[]
    emotions?: Emotion[]
}

let voiceCache: Promise<Voice[]> | undefined

currentUserChangeDispatcher.subscribe(() => voiceCache = undefined)

export function getVoices() {
    return voiceCache ||= http.get<{data: Voice[]}>(`${BACKEND.api}/tts/voices`)
        .then(({data}) => data)
        .catch(error => {
            voiceCache = undefined
            throw error
        })
        .catch(handleHttpError)
}

// TODO убрать, когда бэкенд начнет возвращать лимит
export const demoSynthesisCharacterLimit = 500

let demoVoiceCache: Promise<Voice[]> | undefined

export function getDemoVoices() {
    return demoVoiceCache ||= http.get<{data: Voice[]}>(`${BACKEND.api}/demo/tts/voices`)
        .then(({data}) => data)
        .catch(error => {
            demoVoiceCache = undefined
            throw error
        })
        .catch(handleHttpError)
}

export function playAudio(src: string) {
    audioElement.src = src

    return audioElement.play()
}

export function pauseAudio() {
    audioElement.pause()
}

export function resumeAudio() {
    audioElement.play()
}

export function subscribeOnEvents(handlers: Partial<Record<keyof HTMLMediaElementEventMap, () => void>>) {
    const items = Object.entries(handlers)

    items.forEach(([key, handler]) => audioElement.addEventListener(key, handler))

    return () => items.forEach(([key, handler]) => audioElement.removeEventListener(key, handler))
}

export type SoundStatus = 'playing' | 'paused' | 'stopped'

export const exampleTexts: {[Key: string]: string[] | undefined, default: string[]} = {
    default: [
        'To get started, enter your text here.\n',
        'Find the right voice.\n',
        'For best results, play with punctuation marks.\n',
        'You can change the stress with the plus sign, for example: rec+ord and r+ecord.\n',
        'Manage pauses with markup. sil<[2.5]> This was a 2.5 second pause.',
    ],
    kk: [
        'Бастау үшін мәтінді осы жерге енгізіңіз.\n',
        'Дұрыс дауысты таңдаңыз.\n',
        'Ең жақсы нәтижеге жету үшін тыныс белгілерімен ойнаңыз.\n',
        'Сөздегі екпінді қосу белгісін пайдаланып өзгертуге болады.\n',
        'Белгілері бар үзілістерді басқарыңыз. sil<[2.5]> Бұл 2,5 секундтық үзіліс болды.',
    ],
    ru: [
        'Для нач+ала работы введите Ваш текст сюда.\n',
        'Подберите подходящий голос.\n',
        'Чтобы добиться наилучшего результата, поиграйте знаками препинания.\n',
        'Вы можете менять ударение зн+аком плюс, например: зам+ок и з+амок.\n',
        'Управляйте паузами с помощью разметки. sil<[2.5]> Это была пауза в 2,5 секунды.',
    ],
}

export const generateExampleText = (languages: string[]) => {
    const offset = Math.random() * languages.length | 0

    return exampleTexts.default
        .map((_, index) => (exampleTexts[languages[(index + offset) % languages.length]] || exampleTexts.default)[index])
        .filter(Boolean)
        .join('')
}
